import { useEffect, useState } from "react"
import axios from "axios"
import isEmpty from "lodash/isEmpty"
const parseBooking = require("../utils/parseBooking")
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const useTenantInfo = ({ tenant, tenantUrl, tenantId }) => {
  const [tenantInfo, setTenantInfo] = useState(tenant || {})
  const [loading, setLoaded] = useState(true) // this value needs to be double-checked before using

  // Get latest
  useEffect(() => {
    if (tenantUrl || tenantId) {
      let url = `TenantInfo?code=${apiCode}&public=true`
      if (tenantId) url += `&tenantId=${tenantId}`
      const requestObj = tenantUrl ? { tenant_name_as_url: tenantUrl } : {}
      const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
      const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
      api.post(url, requestObj).then(response => {
        if (response && response.data) {
          const data = response.data || {}
          // TODO: This should match gatsby-node or create a function to get these to match
          const info = {
            tenantTimestamp: data._ts,
            tenantTimezone: data.tenant_timeZone,
            tenantId: data.tenantId,
            tenantChatClients: data.tenant_chat_clients,
            tenantPaymentAccounts: data.tenant_payment_accounts,
            allowManualBookingRequests: data.allowManualBookingRequests || false,
            enableEmailSubscription: data.enableEmailSubscription || false,
            tenantName: data.tenant_name,
            tenantIntro: data.tenant_intro || "",
            tenantThemeAccent: data.tenant_theme_accent || "",
            previewTenantId: data.preview_tenant_id || "",
            productionTenantId: data.prod_tenant_id || "",
            tenantSparkleNumberTextOverride:
              data.tenant_sparklenumber_text_override || "",
            tenantSparkleNumberHide: data.tenant_sparklenumber_hide || false,
            tenantUrl:
              data.tenant_name_as_url_current || data.tenant_name_as_url,
            tenantImageUrl: data.tenant_image_url,
            tenantAppointmentService: data.tenant_appointmentService,
            tenantNylasAccountId:
              !data.tenant_calendars || data.tenant_calendars.length === 0
                ? ""
                : data.tenant_calendars[0].nylas_account_id,
            // Trying to make sure data is parsed the same way between build + latest
            tenantAppointmentTypes: (data.tenant_appointmentTypes || [])
              .map(aptType => ({ ...aptType, isGroupClass: false }))
              .map(parseBooking),
            tenantGroupClasses: (data.tenant_groupClasses || [])
              .map(aptType => ({ ...aptType, isGroupClass: true }))
              .map(parseBooking),
            tenantAdditionalLinks: data.tenant_additionalLinks,
            tenantSendFileActions: data.tenant_sendFileActions,
            tenantProducts: data.tenant_products,
            tenantContributionProduct: data.tenant_adhoc_contribution_product,
          }

          // Always get latest if there is any
          let serverHasUpdates = false
          if (
            (tenantInfo || {}).tenantTimestamp &&
            (info || {}).tenantTimestamp &&
            info.tenantTimestamp > tenantInfo.tenantTimestamp
          ) {
            serverHasUpdates = true
          }

          if (isEmpty(tenantInfo) || serverHasUpdates) {
            setTenantInfo(info)
          }
          setLoaded(false)
        } else {
          setLoaded(false)
        }
      })
    }
  }, [tenantUrl, tenant, tenantId, tenantInfo])

  return [
    tenantInfo,
    loading, // this value needs to be double-checked before using... not currently being used
  ]
}

export default useTenantInfo
